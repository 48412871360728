import facemask from "../video/iris-covid19/facemask.mp4"
import social_distancing from "../video/iris-covid19/social_distancing.mp4"
import attendance from "../video/iris-covid19/log_my_face.mp4"

var md5 = require("md5")
var headerEventAdded = null
var currentScroll = 0
var scroll = 0
var API_URL = "https://cdn.capture.techulus.in/"
var api_key = "ad7d5ce2-885a-4ec7-87a0-f74b20c957d8"
var api_secret = "4a16183e-679b-4eb5-b48b-5c30a857fbd0"
const targetNewsUrls = {
  entrepreneur: encodeURIComponent(
    "https://www.entrepreneur.com/article/344190"
  ),
  analyticsindiamag: encodeURIComponent(
    "https://analyticsindiamag.com/how-this-5-year-old-bootstrapped-startup-is-helping-20000-enterprises-gain-actionable-intelligence"
  ),
}

const achievements = [
  {
    id: "foundedin",
    icon: "founded-in",
    text: "Founded in",
    num: "2014",
  },
  {
    id: "funding",
    icon: "funding",
    text: "Funding",
    num: "Bootstrapped",
  },
  {
    id: "locations",
    icon: "locations",
    text: "Locations",
    num: "India, Europe,\nUSA & Canada",
  },
  {
    id: "fortune500",
    icon: "fortune-500",
    text: "Fortune 500 Customers",
    num: "7",
  },
  {
    id: "languages",
    icon: "languages",
    text: "Languages",
    num: "6",
  },
  {
    id: "countries",
    icon: "countries",
    text: "Countries",
    num: "16",
  },
  {
    id: "users",
    icon: "users",
    text: "Users",
    num: "20000+",
  },
  {
    id: "deployments",
    icon: "deployments",
    text: "Deployments",
    num: "75",
  },
]

const members = [
  {
    id: "kunal",
    name: "Kunal Kislay",
    pos: "CEO, Co-Founder",
    img: "kunal_s",
    alt: "Kunal's pic",
    linkedin: "https://www.linkedin.com/in/kunalkislay",
    about: `
    Kunal is a B.Tech IIT Mumbai alumnus with over a decade of experience in enterprise mobility,  Internet of Things, AI, Neural networks and Machine learning. Creating solutions for a vast array of verticals made him understand the pulse of technology and its changing paradigms. Integration Wizards owes its easy adaptation of the most advanced technologies to him. 

    Mentoring a team to tackle impossible challenges is integral to his nature, a trait he actually prides himself on. He led the creation of our flagship product - IRIS, an AI computer vision platform for enterprises. A smooth combination of fearless and fun, working with Kunal is an adrenaline rush for those passionate about technology.
  `,
    expImg: "kunal_l",
  },
  {
    id: "saquib",
    name: "Saquib Khan",
    pos: "CTO, Co-Founder",
    img: "saquib_s",
    alt: "Saquib's pic",
    linkedin: "https://www.linkedin.com/in/saquib-iwizards",
    about:
      "With more than a decade of experience, Saquib serves as the CTO and one of the core product visionaries for the company’s AI-powered applications across various industries. He drives the technological direction of the company while mentoring the next generation of AI developers. From high-level strategic planning to hands-on endeavours, he believes in defining measurable goals and designing the way forward to aggressively pursue those goals. Moreover, he handles complicated technological challenges with a positive attitude and calm confidence. He also oversees the finances.",
    expImg: "saquib_l",
  },
  {
    id: "raman",
    name: "Kumar Raman",
    pos: "Co-Founder & VP of Technology",
    img: "raman_s",
    alt: "Kumar's pic",
    linkedin: "https://www.linkedin.com/in/kumarraman19",
    about:
      "A co-founder and our VP of Technology, Raman believes in being involved at all stages – from strategic decision-making, leading application development, to testing the products with the team on ground level. Thus, he usually splits his time between providing technical consulting to our largest clients, working on our technology strategy, and commanding the development efforts with full authority. His extensive experience allows him to not only seamlessly guide and support our team but also to come up with elegant and scalable applications.",
    expImg: "raman_l",
  },
  {
    id: "aakash",
    name: "Aakash Asim Roy",
    pos: "VP of Technology",
    img: "aakash_s",
    alt: "Aakash's pic",
    linkedin: "https://www.linkedin.com/in/aakash-asim-roy-3429975a",
    about:
      "Aakash leads the deployment of IRIS for retail clients. Ten years ago, his core expertise was in back-end development and integration, he has explored the latest technologies that encourage collaboration and scalability as well as were on the rise in the last ten years. In various capacities, he has dwelled into enterprise mobility, android applications, integration, internet of things, artificial intelligence, machine learning, and computer vision. In his current role, he is always looking for ways to bring efficiency into large-scale deployments for IRIS retail.",
    expImg: "aakash_l",
  },
  {
    id: "yogesh",
    name: "Yogesh Shekhawat",
    pos: "VP of Technology",
    img: "yogesh_s",
    alt: "Yogesh's pic",
    linkedin: "https://www.linkedin.com/in/yshekhawat",
    about:
      "Yogesh brings more than 12 years of knowledge and expertise to the table. With expertise in AI domain fundamentals, related mechanics, and engineering methods to designing end-to-end digital solutions, he mentors the team to develop solutions for mobile, IoT/Devices, AR/VR, ML, and networking advancements while incorporating learning and insights into the overall technology strategy. He is passionate about developing and designing solutions that are innovative and problem-solving solutions. Moreover, he helps teams develop the product on time and within the customer's budget.",
    expImg: "yogesh_l",
  },
  {
    id: "puja",
    name: "Puja Upadhyay",
    pos: "Communications Director",
    img: "puja_s",
    alt: "Puja's pic",
    linkedin: "https://www.linkedin.com/in/puja-upadhyay-20775b9",
    about:
      "Puja helped plot the success story of Integration Wizards by leading the brand communication, public relations, and corporate communication. A bestselling author by Penguin Books, she has over 12 years of experience from various communication verticals such as event management, advertising, and corporate communications, writing lyrics and scripts for corporate videos. Puja has written a commissioned book on the silver jubilee of Karnataka State Road Transport Corporation that won the Public Relation Council of India collateral gold award.",
    expImg: "puja_l",
  },
  {
    id: "dinesh",
    name: "Dinesh Fatehpuria",
    pos:  "VP of Technology",
    img: "dinesh_s",
    alt: "Dinesh's pic",
    linkedin: "https://www.linkedin.com/in/dineshfatehpuria",
    about:
      "Dinesh joined the team at Integration Wizards with diverse experience. He began his career with enterprise mobility, moving on to the Internet of Things, building and finally delving deeper into AI and computer vision. With nearly a decade of experience, he leads a team of ML developers for our computer vision solution, IRIS. Overseeing the product development of IRIS applications, his mantra “quality on time” is reflected through his commitment to keeping projects on track and providing clear communication through the entire project.",
    expImg: "dinesh_l",
  },
  {
    id: "jim",
    name: "Jim Shields",
    pos: "CEO - Integration Wizards Europe",
    img: "jim_s",
    alt: "Jim's pic",
    linkedin: "https://www.linkedin.com/in/jimshieldsuk",
    about:
      "A technocrat and a veteran business leader, the CEO of Integration Wizards Solutions Europe, Jim Shields brings more than 30 years of experience in running large product development, operations and support teams in multinationals and SMEs. From software product development, H/W & S/W product delivery, system integration, infrastructure deployment, process transformation, customer to live product support - he has done it all. While he had previously focused on telecoms and/or financial services systems, he is keen to explore the AI domain and be the growth catalyst for Integration Wizards Solutions Europe. He is a strong leader with excellent coaching and motivational skills who is able to drive teams to deliver challenging programs successfully.",
    expImg: "jim_l",
  },
  {
    id: "subhash",
    name: "Subhash Sharma",
    pos: "COO - Integration Wizards Europe",
    img: "subhash_s",
    alt: "Subhash's pic",
    linkedin: "https://www.linkedin.com/in/sharmasubhash",
    about:
      "Founder of multiple companies with extensive experience in domestic and international markets, Subhash Sharma is the COO for Integration Wizards Solutions Europe. 27+ years of experience and an alumnus of IIT Kharagpur and IIM Ahmedabad, he has built strategic partnerships with clients across the globe and delivered multimillion-pound bottom-line growth. While his expertise lies in sales and marketing, and in the past, he has worked in large IT services companies such as Wipro and Tech Mahindra, his innate sense of curiosity and hunger for learning has catalysed his decision to explore this domain of AI.",
    expImg: "subhash_l",
  },
]

const irisFeatures = [
  {
    key: "enterpriseReady",
    label: "Enterprise Ready",
    desc:
      "IRIS fits easily with any system and can be personalized according to your existing infrastructure. It can be deployed for both on-premise as well as synchronized with your cloud.",
    icon: "enterprise-ready",
  },
  {
    key: "plugPlay",
    label: "Plug & Play",
    desc:
      "IRIS can be connected to any existing CCTV network to monitor performance, people, and assets at your premises.",
    icon: "plug-play",
  },
  {
    key: "userDefinedAlerts",
    label: "User Defined Alerts",
    desc:
      "Analyses data real-time and sends alerts accordingly. It is useful for immediate notification on stock-outs in stores, fire alerts, security breach, non-compliance of safety measures, etc.",
    icon: "user-defined-alerts",
  },
  {
    key: "dashboard",
    label: "Dashboard",
    desc:
      "Realtime personalized dashboards available on Web, iOS and Android platforms.",
    icon: "dashboard",
  },
  {
    key: "multichannelStreaming",
    label: "Multichannel Streaming",
    desc:
      "Ability to perform real-time identification and analysis of multiple video streams, i.e. it can analyse feeds from various CCTV at the same time.",
    icon: "multichannel-streaming",
  },
  {
    key: "sceneOptimization",
    label: "Scene Optimization",
    desc:
      "We do not believe in one-model-fits-all. IRIS AI models are optimized for your premises.",
    icon: "scene-optimization",
  },
]

const slFeatures = [
  {
    key: "meaningfulInsights",
    label: "Meaningful Insights",
    desc:
      "Stay ahead of competition by unlocking the power of machine learning.",
  },
  {
    key: "platformAgnostic",
    label: "Platform Agnostic",
    desc:
      "Freedom to choose application development technology and deployment model.",
  },
  {
    key: "rapidDevTemplates",
    label: "RapidDev Templates",
    desc: "Prebuilt codebase to jumpstart development.",
  },
  {
    key: "iotEnabled",
    label: "IOT Enabled",
    desc: "Easily scalable to support thousands of devices.",
  },
  {
    key: "azureEcosystem",
    label: "Azure Ecosystem",
    desc: "Connect with other technology & solutions provider on the Cloud.",
  },
  {
    key: "unifiedManagementConsole",
    label: "Unified Management Console",
    desc: "Single console to deploy, monitor & control E2E solution.",
  },
  {
    key: "analytics",
    label: "Analytics",
    desc: "Integrated with MS PowerBI to churn information from data.",
  },
  {
    key: "interAppCommunication",
    label: "Inter App Communication",
    desc:
      "Applications can share data with each other, with or without a network.",
  },
]

const slmFeatures = [
  {
    key: "privateGooglePlaystore",
    label: "Private Google Playstore",
    desc:
      "Enterprises can install private apps remotely to users’ devices via a private Google Play store.",
    icon: "private-google-playstore",
  },
  {
    key: "bulkEnrollment",
    label: "Bulk Enrollment",
    desc:
      "This feature allows admin to enroll multiple devices using one QR code.",
    icon: "bulk-enrollment",
  },
  {
    key: "applicationLogsReports",
    label: "Application Logs & Reports",
    desc:
      "App reports such as app failures are reported and debugged remotely.",
    icon: "application-logs-reports",
  },
  {
    key: "groupLevelConfiguration",
    label: "Group Level Configuration",
    desc:
      "All device settings in one group can be configured together in one go, instead of individual devices.",
    icon: "group-level-configuration",
  },
  {
    key: "applicationManagement",
    label: "Application Management",
    desc:
      "Intelligent and controlled app upgrades to avoid any transaction failures or inconvenience for customers.",
    icon: "application-management",
  },
  {
    key: "assetManagement",
    label: "Asset Management",
    desc:
      "Admin can create and group devices for specific users or custom requirements.",
    icon: "asset-management",
  },
  {
    key: "policiesCompliance",
    label: "Policies and Compliance",
    desc:
      "Apply security and password compliance to keep device safe and secure.",
    icon: "policies-compliance",
  },
  {
    key: "geolocation",
    label: "Geolocation",
    desc:
      "Admin can find the location of group or individual devices at any time.",
    icon: "geolocation",
  },
  {
    key: "bringYourOwnDevice",
    label: "Bring Your Own Device",
    desc:
      "Employees can bring their own device and get MDM on it without getting their privacy disturbed.",
    icon: "bring-your-own-device",
  },
  {
    key: "workProfile",
    label: "Work Profile",
    desc:
      "A different partition or a work profile is created in the phone for enterprise use.",
    icon: "work-profile",
  },
  {
    key: "kioskLauncherApp",
    label: "Kiosk Launcher App",
    desc:
      "This feature enables users to use configured enterprise applications in a customized interface without having access to device settings and other applications.",
    icon: "kiosk-launcher-app",
  },
]

const irisTBContents = [
  {
    key: "tb00001",
    label: "One of India’s leading retail chains",
    desc:
      "100s of CCTV cameras are in operation with IRIS to provide real-time footfall, demographics, customer engagement, repeat customer analysis, sentiment analysis, staff management, store layouts, merchandising, and much more.",
    img: "",
    company: "Tanishq",
  },
  {
    key: "tb00002",
    label: "India's leading Hydrocarbon Engineering Organization",
    desc:
      "IRIS analyses various aspects of machine management including machine utilisation, running time, maintenance mode, downtime, input/output productivity, as well as safety aspects.",
    img: "",
    company: "LnTH",
  },
  {
    key: "tb00003",
    label:
      "Face recognition based attendance system for one of the largest construction firms in India",
    desc:
      "Our product, IRIS uses feed from CCTV cameras and deep learning to detect employees through facial recognition, count the number of workers entering and exiting a construction site, detect safety compliance for the workers at the site while also helping them detect vehicles moving in and out of a site by reading the vehicle number plate at one of the largest construction companies in India.",
    img: "",
    company: "LnTC",
  },
  // {
  //   key: 'tb00004',
  //   label: 'A leading Fortune 500 petroleum retail chain from India',
  //   desc: 'IRIS tapped into various aspects of customer requirements that include detecting SOP Compliance, measure efficiency, safety risks, customer profiles, and loyalty in real-time, and raise alarms so as to potentially intervene in case of any breaches.',
  //   img: '',
  //   company: 'HPCL'
  // },
  // {
  //   key: 'tb00005',
  //   label: 'A leading multinational in chocolates, biscuits and powdered beverage',
  //   desc: 'They use IRIS for complete tracking of their warehouse and monitoring operational efficiency accurately in real-time. IRIS provides high level details which can be drilled down to individual assets, trucks and docks utilization metrics to improve their operational efficiency, analyze workforce utilization, and more.',
  //   img: '',
  //   company: 'Mondelez'
  // },
  {
    key: "tb00006",
    label: "Leading solar panel and solar power products manufacturer of India",
    desc:
      "IRIS is redefining security and safety compliance by using drones for one of largest solar power plants in India. Using automated drones, IRIS detects any intrusion, fire, theft, and other safety aspects.",
    img: "",
    company: "TPS",
  },
  {
    key: "tb00007",
    label: "One of India's leading FMCG players",
    desc:
      "Integration Wizards' compliance solution for Godrej can detect fire within 10 secs and generate alarms and notifications for the same. IRIS works by analysing data from existing cctv cameras and the deep learning model is trained for custom scenarios based on their requirements.",
    img: "",
    company: "Godrej",
  },
  {
    key: "tb00008",
    label: "One of the largest bike manufacturers",
    desc:
      "The company uses IRIS for a real-time tracking solution for yard management which gives them real-time visibility of all the bikes at any given time, helping them reduce the aging cost of vehicles, optimize vehicle storage, and remove dependability on employees for tracking.",
    img: "",
    company: "Bajaj",
  },
]

const slTBContents = [
  {
    key: "tb00001",
    label: "One of the largest pharmaceutical companies in the world",
    desc:
      "We are proud to help them run several of their key business processes seamlessly by enabling their various teams and higher management, to work across a portfolio of over 10 Mobile applications used in multiple countries and languages by several thousand users.",
    img: "",
    company: "Novo Nordisk",
  },
  {
    key: "tb00002",
    label:
      "One of the largest Communications and Logistics Company in Nordic Region",
    desc:
      "Our Shop and Driver application ensures the system was updated with each delivery. Be it busy cities or remote mountains, we track each parcels and transactions without any hiccups.",
    img: "",
    company: "Postnord",
  },
  // {
  //   key: 'tb00003',
  //   label: 'Globally diversified technology and multi industrial leader',
  //   desc: 'They use our platform for service management to provide a seamless field service experience to its 4200 field technicians in the US and Canada. Using Silverline, the end users can track and manage their work orders, repairs history, schedule an appointment, perform routine maintenance and much more.',
  //   img: '',
  //   company: 'Novaspect'
  // },
  // {
  //   key: 'tb00004',
  //   label: '',
  //   desc: '',
  //   img: '',
  //   company: 'Heineken'
  // }
]

const slmTBContents = [
  {
    key: "tb00001",
    label:
      "One of the largest Communications and Logistics Company in Nordic Region",
    desc:
      "Silverline MDM aids them in providing an end-to-end solution for delivery as well as device management. The admin can track the parcel journey all the way from the terminal to the customer. At present, 100+ devices are being managed on a group and individual level across Norway, Finland and Denmark.",
    img: "",
    company: "Postnord",
  },
]

const workBenefits = [
  {
    key: "collaboration",
    label: "Collaboration",
    desc:
      "Everyone here is committed to maintaining an environment where we share ideas, support each other and celebrate our success.",
    icon: "collaboration",
    swipe: true,
  },
  {
    key: "onSiteOpportunities",
    label: "On-site Opportunities",
    desc: "Exciting on-site opportunities across different countries.",
    icon: "on-site",
    swipe: false,
  },
  {
    key: "innovation",
    label: "Innovation",
    desc:
      "Ideas are always encouraged. If someone has an idea for a new product or service the firm is listening, anyone can bring forth ideas for consideration.",
    icon: "innovation",
    swipe: true,
  },
  {
    key: "competitiveCompensation",
    label: "Competitive Compensation",
    desc:
      "Appraisals and bonuses, family’s financial health with group life insurance - we have got you covered!",
    icon: "compensation",
    swipe: false,
  },
  {
    key: "handsTech",
    label: "Hands on Tech",
    desc:
      "We are constantly looking to push the boundaries of our products as well as our abilities. Get hands on experience of working on cutting edge technology with our own GPUs and drones.",
    icon: "hands-on",
    swipe: true,
  },
  {
    key: "mentorship",
    label: "Mentorship",
    desc:
      "Work with some of the most talented and experienced techies as they reshape the future of technologies.",
    icon: "mentorship",
    swipe: false,
  },
  {
    key: "equalPay",
    label: "Equal Pay",
    desc:
      "Equality is coded into our company culture. We are unbiased and so is our payscale, we don’t differentiate on gender.",
    icon: "equal-pay",
    swipe: true,
  },
  {
    key: "roomGrow",
    label: "Room to Grow",
    desc:
      "Learning is built into every role. Opportunity to learn not just on the latest technologies, languages but also build your interpersonal skills.",
    icon: "room-grow",
    swipe: false,
  },
]

const irisCovid19Features = [
  {
    key: "cctv_fea",
    title: "Works on Existing CCTV Infrastructure",
    text: "Cost effective, less downtime.",
  },
  {
    key: "notification_fea",
    title: "Real-time Notifications and Alerts",
    text:
      "Location-specific alarms, immediate action, provides data for improved planning.",
  },
  {
    key: "webnmobile_fea",
    title: "Native Mobile/Web Applications",
    text:
      "Dashboards provide detailed information on safety compliance within an enterprise. Configure alarms and assign stakeholders to receive notifications.",
  },
  {
    key: "cloudonprem_fea",
    title: "Cloud & On-prem Deployment Options",
    text:
      "Supported on Azure, AWS, Google cloud, with the option of on-prem deployment.",
  },
  {
    key: "compliance_fea",
    title: "Compliant",
    text:
      "In line with the GDPR, PDPA, and other regional compliance requirements and regulations.",
  },
]

const irisCovid19Options = [
  {
    key: "facemask",
    title1: "Detect to Deter",
    title2: "Face mask compliance",
    detail: `
    N95, Surgical Masks as well as custom masks are detected as long as they are in field of view of the camera. If an individual is detected without face mask, an alarm is raised.
  `,
    points: [
      "Can detect up to 64 faces in frame.",
      "Accuracy in excess of 98%.",
      "Reports and dashboards for compliance audits.",
      "Realtime notifications on WhatsApp, SMS, Email and IVR.",
      "Can interface with Buzzers, PA systems.",
    ],
    swipe: true,
    video: facemask,
  },
  {
    key: "social_distancing",
    title1: "Measure to Manage",
    title2: "Social distance monitoring",
    detail: `
    A configurable tolerance threshold is set for every camera. When two or more individuals do not maintain the defined threshold in terms of distance for a specific period of time, an alarm is raised.
  `,
    points: [
      "When threshold is not maintained, alarm is raised.",
      "Can detect up to 64 people in frame.",
      "Accuracy in excess of 98%.",
      "Dashboard of Physical Distancing Index for compliance audits.",
      "Realtime notifications on WhatsApp, SMS, Email and IVR.",
      "Interface with Buzzers, PA systems.",
      "Supports IR Mode.",
      "Can be used for detecting crowd formation.",
    ],
    swipe: false,
    video: social_distancing,
  },
  {
    key: "attendance",
    title1: "Scan to Safeguard",
    title2: "Face-recognition attendance app",
    detail: `
    LogMyFace is a location-specific facial-recognition app for contactless attendance. It can be used for both in-office and field service employees. When employees are in a specified area - inside the office or client’s place, they can open the app and log-in their attendance by letting it scan their face.
  `,
    points: [
      "Recognises faces even when mask is on.",
      "User can log-in attendance anywhere in a specified location.",
      "Accuracy in excess of 98%.",
      "Reports and dashboards for compliance audits.",
    ],
    swipe: true,
    video: attendance,
  },
]

export const updateFloatBtn = visible => {
  let $floatConBtn = document.getElementById("float-cont-btn")
  if ($floatConBtn) {
    let $cont = document.getElementById("main-container")
    let bottom = window.scrollY - ($cont.clientHeight - window.innerHeight)
    let btm = window.innerWidth < 321 ? "1rem" : "1.5rem"
    if (typeof visible === "undefined") {
      btm = $floatConBtn.classList.contains("form-visible")
        ? btm
        : "50px + " + btm
    } else {
      btm = visible ? btm : "50px + " + btm
    }
    if (bottom >= 0) {
      $floatConBtn.style.bottom = "calc(" + btm + " + " + bottom + "px)"
    } else {
      $floatConBtn.style.bottom = "calc(" + btm + ")"
    }
  }
}

export const headerUpdate = () => {
  let $nav = document.getElementById("main-navbar")
  updateFloatBtn()
  let diff = window.scrollY - currentScroll
  scroll += Math.abs(diff)
  // if(diff > 0) {
  //   if(scroll > 80) {
  //     $nav.style.height = '0px';
  //     scroll = 0;
  //     $cont.classList.remove('header-visible');
  //     $cont.classList.add('header-invisible');
  //   }
  // } else {
  if (scroll > 10 || window.scrollY === 0) {
    // $nav.style.height = '50px';
    if (window.scrollY === 0) {
      $nav.classList.remove("scrolled")
    } else if (!$nav.classList.contains("scrolled")) {
      $nav.classList.add("scrolled")
    }
    // scroll = 0;
    // $cont.classList.remove('header-invisible');
    // $cont.classList.add('header-visible');
  }
  // }
  currentScroll = window.scrollY
}

export const navbarUpdate = (nav, subNav) => {
  if (nav) {
    const el = document.querySelector(".navbar-item." + nav)
    el &&
      !el.classList.contains("active-link") &&
      el.classList.add("active-link")
    if (subNav && el) {
      const _el = el.querySelector(".navbar-item." + subNav)
      _el &&
        !_el.classList.contains("active-link") &&
        _el.classList.add("active-link")
    }
    document.getElementById("main-navbar").classList.remove("no-item-selected")
  } else {
    document.getElementById("main-navbar").classList.add("no-item-selected")
  }
}

export const setHeaderEventFlag = () => {
  headerEventAdded = true
}

export const getHeaderEventFlag = () => headerEventAdded

export const getMembers = () => members

export const getAchievements = () => achievements

export const getIrisFeatures = () => irisFeatures

export const getSlFeatures = () => slFeatures

export const getSlmFeatures = () => slmFeatures

export const getIrisTbContents = () => irisTBContents

export const getSlTbContents = () => slTBContents

export const getSlmTbContents = () => slmTBContents

export const getWorkBenefits = () => workBenefits

export const getIrisCovid19Features = () => irisCovid19Features

export const getIrisCovid19Options = () => irisCovid19Options

export const getNewsSlideImg = (name, w, h) => {
  const input_url = targetNewsUrls[name]
  let options =
    "&delay=1&top=400" +
    (w ? "&resizeWidth=" + w : "") +
    (h ? "&resizeHeight=" + h : "")
  let hash = md5(api_secret + "url=" + input_url + options)
  let url = API_URL + api_key + "/" + hash + "/image?url=" + input_url
  return url + options
}

export const setDefaultHeaderTheme = isColored => {
  let $nav = document.getElementById("main-navbar")
  if (isColored) {
    $nav.classList.remove("white")
    !$nav.classList.contains("colored") && $nav.classList.add("colored")
  } else {
    $nav.classList.remove("colored")
    !$nav.classList.contains("white") && $nav.classList.add("white")
  }
}
